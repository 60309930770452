.welcomeWrap {
    width: 100%;
    height: 100vh;
    background: var(--background-primary);
    padding: 0 8%;
    overflow: auto;

    .header {
        display: flex;
        justify-content: space-between;
        padding: var(--padding_default) 0;
        align-items: center;
        height: 10vh;

        .logowrapper {
            padding: 12px 20px;
            width: 10vw;
            background: white;
            border-radius: var(--border_radius);

            .logo {
                width: 100%;
            }
        }
    }

    .introWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .imageWrapper {
            width: 50%;

            .image {
                width: calc(33.33% - 10px);
                height: 150px;
                background: transparent;
                margin: 5px;
                display: inline-block;
                overflow: hidden;
                border-radius: var(--border_radius);

                img {
                    max-width: 100%;
                    object-fit: fill;
                }

                .overlayText {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    background: rgba(0, 0, 0, 0.5);
                    color: white;
                    padding: 10px;
                    font-size: 12px;
                }
            }
        }

        .intro {
            width: 50%;
            font-size: 14px;
            padding-right: 20px;
            line-height: 1.8;
            letter-spacing: 1px;

            span {
                color: var(--font_color_alt);
                font-weight: bold;
                font-size: 18px;
            }
        }

    }



    .introResponsive {
        flex-direction: column;
        justify-content: stretch;

        .intro {
            width: 100%;
            font-size: 12px;
            padding-right: 20px;
            line-height: 1.8;
            letter-spacing: 1px;

            span {
                color: var(--font_color_alt);
                font-weight: bold;
                font-size: 16px;
            }
        }

        .imageWrapper {
            width: 100%;

            .image {
                width: calc(50% - 10px);
                height: 135px;
                background: transparent;
                margin: 5px;
                display: inline-block;
                overflow: hidden;
                border-radius: var(--border_radius);

                img {
                    max-width: 100%;
                    object-fit: fill;
                }

                .overlayText {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    background: rgba(0, 0, 0, 0.5);
                    color: white;
                    padding: 10px;
                    font-size: 12px;
                }
            }
        }
    }



    .footer {
        display: flex;
        justify-content: space-between;
        padding-top: 40px;
        margin: 0 -10px;

        .varietyCard {
            border-radius: var(--border_radius);
            background: white;
            padding: 40px;
            color: var(--font_color);
            margin: 10px;
            letter-spacing: 1px;

            .varietyCardHeader {
                font-size: 16px;
                font-weight: bold;
                color: var(--font_color_alt);
                padding-bottom: 10px;
            }

            .desc {
                font-weight: 14px;
            }
        }
    }

}

@media (max-width: 1000px) {
    .welcomeWrap {
        .header {
            .logowrapper {
                width: 40%;
            }
        }
    }

    .footer {
        flex-direction: column;
        // overflow-x: auto;
    }
}