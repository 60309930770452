@import '/src/index.scss';

.varietyContainer {
    
    @extend .columnClass;
    // display: flex;
    // flex-direction: row;
    // justify-content: space-around;
    // height: calc(100% - 50px);
    // display: inline-block;
    // vertical-align: top;
    overflow-y: auto;
    margin: var(--margin_default) 0;
    padding: 0 var(--margin_default);
    border-radius: var(--border_radius);

    .varietyWiseList{
        // 
        // @extend .columnClass;
        // width: 30%;
        // height: calc(100% - 10px);
        padding: 10px;
        background: white;
        border-radius: var(--border_radius);
    }

   
}

.popDetails{
    // width: 50%;
    @extend .columnListCard;
    // height: calc(100% - 20px);
    overflow: auto;
    border-radius: var(--border_radius);
    background: white;
    margin:  var(--margin_default);
    padding: 0 var(--margin_default);
}

@media screen and (max-width: 768px) {
    .cardSection {
        padding: 0px 30px;

        .statusInfo {
            .count {
                font-size: 28px;
            }

            .info {
                font-size: 11px;
            }
        }
    }
}

@media screen and (min-width: 1000px) {
    .popDetails{
        .mobileVersion{
            // display: none !important;
            
            overflow: hidden !important;
            height: calc(100% - 20px);
        }
    }
}