@import '/src/index.scss';
@import '../../../modules/TradeManagementModule/components/consignmentList/consignmentList.scss';

.notificationCardInline{
    @extend .customCard;
    background: white;
    margin: var(--margin_default);
    width: calc(33.33% - 2*var(--margin_default));
    display: flex;
    .consignmentCard{
        .cardFooter{
            min-height: 48px;
        }
    }
    @media screen and (max-width: 1400px) {
        width: calc(50% - 2*var(--margin_default));
    }
    @media screen and (max-width: 1000px) {
        width: calc(100% - 2*var(--margin_default));
    }
}

.headerPart{
    height: fit-content;
    background: var(--background-secondary);
    border-radius: 10px;
    margin: 0 5px;
}