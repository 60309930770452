@import '/src/index.scss';
@import '../../../modules/TradeManagementModule/components/consignmentList/consignmentList.scss';

.emptyRequest {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .msg {
        @extend .statusDesc;
        margin: 40px 0;
        color: red;
        background: rgb(248, 222, 222);
    }
}

.list {
    // @extend .columnListCard !optional;
    // display: block;
    // height: calc(100% - 20px);

    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    margin: 0 -10px;

    .listHeader {
        height: 100px;
        width: 100%;
    }

    .listInner {
        width: 100%;
        overflow: auto;
    }

    .filterlessCard {
        height: calc(100% - 110px);
    }
}

.inviteCard {
    padding: var(--padding_custom);
    color: var(--font_color);
    background: white;
    margin: var(--margin_default) 20px;
    border-radius: var(--border_radius);
    border: thick double #67903C;

    @media screen and (max-width: 768px) {
        margin: var(--margin_default);
    }

}


.inviteContent {
    display: flex;
    flex-direction: column;
    // height: 135px;
    overflow: auto;


    .inviteHeader {
        display: flex;
        padding-bottom: var(--padding_default);
        // flex-direction: row;

        .headerTitle {
            font-size: 14px;
            font-weight: 600;
            color: var(--font_color);
            width: 80%;
        }

        img {
            width: 20px;
            height: 20px;
        }
    }

    .inviteBody {
        display: flex;

        // justify-content: space-between; 
        .inviteMeta {
            width: 100%;

            .meta {
                color: var(--secondary-color);
                width: 100%;
                padding: 5px 0;
                img {
                    width: 15px;
                    height: 15px;
                    margin-right: 5px;
                }
            }

        }
    }



    .inviteFooter {
        padding-top: var(--padding_default);
        display: flex;
        justify-content: flex-end;
        height: 45px;
    }
}

.pendingCardInline {
    @extend .customCard;
    // border: ridge  #fada9f;
    // background: var(--background-gradient-active);
    // border-color:  var(--background-gradient-completed);
    // margin: var(--margin_default);
    width: calc(33.33% - 4*var(--margin_default));
    background: white;
    display: flex;

    @media screen and (max-width: 1400px) {
        width: calc(50% - 2*var(--margin_default));
    }

    @media screen and (max-width: 1000px) {
        width: calc(100% - 2*var(--margin_default));
    }
}