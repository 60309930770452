@import '/src/index.scss';

.qrContainer {
    width: 100%;
    // height: calc(100% - 2*var(--margin_default));
    height: 100%;
    background: var(--background-primary);
    padding: 0 8%;

    .headerWrap {
        height: 20%;
        // padding: var(--padding_default) 0;
        // margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .heading {
            display: flex;
            justify-content: space-between;
            padding: var(--padding_default) 0;
            margin: var(--margin_default) 0;
            align-items: center;

            .logowrapper {
                padding: 12px 20px;
                width: 10vw;
                background: white;
                border-radius: var(--border_radius);

                .logo {
                    width: 100%;
                }
            }
        }

        .introWrap {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .intro {
                font-size: 18px;
                line-height: 1.5;
                letter-spacing: 1px;
                color: var(--font_color);
                font-weight: bold;

                span {
                    color: var(--font_color_alt);
                    font-weight: 100;
                }
            }
        }
    }

    .introImages {
        // overflow: auto;
        // white-space: nowrap;
        padding: 10px;
        // width: 50%;

        .image {
            width: calc(33.33% - 10px);
            height: fit-content;
            background: transparent;
            margin: 5px;
            display: inline-block;
            vertical-align: top;
            overflow: hidden;
            border-radius: var(--border_radius);

            img {
                width: 100%;
                object-fit: fill;
            }
        }
    }

    .qrBodyWrap {
        height: 80%;
        overflow: hidden;
        display: flex;

        .consignmentWrap {
            width: 30%;
            @extend .columnListCard;
            margin-left: 0;
            background: #eee;
            padding: var(--padding_custom);
            // height: fit-content;
            height: calc(100% - 2*var(--margin_default));
            overflow: auto;

            h5 {
                color: var(--font_color_alt);
                font-weight: bold;
            }

            h6 {
                font-size: 14px;
                font-style: italic;
                color: var(--font_color);
            }

            .desc {
                padding: 20px 0;
                font-style: italic;
            }
        }

        .processCardWrap {
            background: white;
            border-radius: var(--border_radius);
            margin: var(--margin_default);
            width: calc(70% - 2*var(--margin_default));
            // height: calc(100% - 2*var(--margin_default));
            padding: var(--padding_default);
            overflow: auto;

            .processHeader {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .processTitle {
                    font-size: 12px;
                    font-weight: bold;
                    color: var(--font_color);
                }
            }

            .contentCard {
                width: 100%;
                height: fit-content;

                .consignmentDetails {
                    border-radius: var(--border_radius);
                    padding: var(--padding_custom);
                    background: var(--primary-color-light_alt);
                    margin-bottom: var(--margin_default);
                }

                .processContent {
                    border: 1px solid var(--border-color);
                    border-radius: var(--border_radius);
                    width: 100%;
                    margin-bottom: var(--margin_default);
                    background: white;

                    .processContentHeader {
                        padding: var(--padding_default);
                        color: var(--font_color_alt);
                        font-weight: bold;
                        width: 100%;
                        background: var(--border-color);
                        border-radius: var(--border_radius) var(--border_radius) 0 0;
                    }

                    .processContentBody {
                        padding: 0 var(--padding_default);
                    }
                }
            }
        }
    }
}

.exporterCard {
    width: 100%;
    padding: var(--padding_default);
    background: var(--background-primary);
    border-radius: var(--border_radius);
    margin: var(--margin_default) 0;

    h6 {
        font-size: 14px;
        font-weight: bold;
        color: var(--font_color);
    }

    p {
        color: var(--font_color_alt);
        padding-bottom: 10px;
        margin: 0;
    }
}

.noDataWrap{
    width: 100%;
    height:  70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .noData{
        font-size: 16px;
        font-weight: bold;
        color: red;
    }
    .noDataDesc{
        font-size: 14px;
        color: var(--font_color);
    }
}

@media (max-width: 1000px) {
    .qrContainer {
        padding: 0 10px;
        height: fit-content;

        .headerWrap {
            height: 14%;
            margin-bottom: 1rem;

            .heading {
                justify-content: center;

                .logowrapper {
                    width: 40%;
                }
            }

            .introWrap {
                .intro {
                    font-size: 14px;
                }
            }
        }

        // .introImages{
        //     // height: 15%;
        //     // overflow-y: hidden;
        // }

        .qrBodyWrap {
            flex-direction: column;
            overflow: auto;

            .consignmentWrap,
            .processCardWrap {
                width: 100%;
                margin: 0;
                margin-bottom: var(--margin_default);
            }
        }
    }
}