.hasAttachmentsWrapper{
    background: #CDD971;
}
.upload_attachments_wrapper{
    margin: 10px 0;
    border-radius: 15px;
    .uploadWrapper{
        border: 1px solid #eee;
        padding: 10px;
        border-radius: 15px;
        display: flex;
        justify-content: space-between;
        background: rgb(249 249 249 / 85%);
        align-items: center;
        .treatfiles{
            padding: 10px;
        }
        .dateInput{
            margin-left: 5px;
            border: 1px solid #eee;
            outline: none;
        }
    }
    .attachmentsWrapper{
        padding: 10px;
        .docTitle{
            color: #365219;
            font-weight: bold;
            cursor: pointer;
            font-size: 10px;
            padding: 5px 15px;
            border-radius: 10px;
            background: #eee;
            margin: 2px;
        }
    }
}


.dropdownWrapper{
    border-radius: 15px;
    color: grey;
    margin-right: 5px;
    width: 55%;
    .dropButton{
        width: 100%;
        background: white;
        color: grey;
        border: none;
        padding: 10px 15px;
        text-align: left;
    }
    .treatTitle{
        color: #365219;
        font-weight: bold;
        .toggleicon{
            width: 20px;
            margin-right: 5px;
            cursor: pointer;
        }
    }
    .treatDesc{
        color: #8699ad;
        font-size: 11px;
    }
}
.uploadContainer {
    position: relative;
    .uploadButton{
        display: flex;
        align-items: center;
        padding: 10px 15px;
        border-radius: 15px;
        background: #eee;
        color: #365219;
        font-variant: normal;
        cursor: pointer;
        width: fit-content;
        text-transform: capitalize;
        border: 1px solid lightgrey;
        &:hover{
            opacity: 0.8;
        }
    }
    .fileInput {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 1;
    }
     
}
  
 
  