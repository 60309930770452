@import '/src/index.scss';

.entityContainer {
    @extend .columnListCard;
    border-radius: var(--border_radius);
    background: white;
    margin: 0;
    padding: 0;
    width: 100%;
    .entityTitle {
        color: var(--secondary-color);
        border: 1px solid var(--border-color);
        border-radius: var(--border_radius);
        .titleValue {
            display: flex;
            justify-content: start;
            align-items: center;
            padding: var(--padding_custom) ;
            border-bottom: 1px solid var(--border-color);
            .title{
                font-weight: bold;
                font-size: 20px;
                color: var(--font_color_alt);
            }
            .subtitle{
                color: var(--secondary-color);
            }
            img{
                width: 40px;
                height: 40px;
                margin-right: 10px;
            }
        }

        .addressValue {
            font-weight: bold;
            font-size: 14px;
            color: var(--font_color_alt);
            padding: var(--padding_custom) ;
            display: flex;
            justify-content: start;
            align-items: flex-start;
            img{
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
        }
    }

    .entity {
        margin: var(--margin_default) 0;
        padding: var(--padding_custom);
        background: var(--background-secondary);
        border-radius: var(--border_radius);

        .name {
            font-size: 14px;
            color: var(--secondary-color);
            font-weight: 600;
            b {
                color: var(--font_color);
            }
        }
        .valueWrap{
            padding: var(--padding_default);
            border-left: 1px solid var(--border-color);
            .attr {
                color: var(--secondary-color);
                b {
                    color: var(--font_color);
                }
            }
        }

        
    }
}