@import '/src/index.scss';

.sidenavContainer{
    @extend .columnListCard !optional;
    justify-content: space-between;
    box-shadow: var(--box-shadow);
    .navUpperSection{
        display: flex ;
        flex-direction: column;
    }

    .sidenavHeader{
        width: 100%;
        padding: 20px;
        .logoWrapper{
            border: 1px solid var(--border-color);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 0;
            .sidenavLogo{
                width: 70%;
                &:hover{
                    cursor: pointer; 
                }
            }
            .appname{
                font-weight: bold;
                color: var(--font_color);
            }
        }
        
    }
    .sidenavMenu{
        padding: 20px;
        width: 100%;
        .menuItem{
            padding: 15px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: var(--font_color);
            cursor: pointer;
            width: 100%;
            &:hover{
                color: var(--font_color_alt);
            }
            .menuItemIcon{
                margin-right: 10px;
                img{
                    width: 20px;
                }
            }
            .menuItemText{
                // width: 70%;
                .username{
                    font-weight: bold;
                    color: var(--font_color);
                }
                .userrole{
                    color: var(--font_color_alt);
                }
            }
            .menuItemCount{
                background: red;
                color: white;
                padding: 2px 5px;
                font-size: 10px;
                border-radius: 50px;
            }
        }
        .usermenu{
            border-radius: var(--border_radius);
            border: 1px solid var(--border-color);
            margin-bottom: var(--padding_default);
            display: flex;
            justify-content: start;
            align-items: center;
            .menuItemIcon{
                img{
                    height: 30px;
                    width: 30px;
                }
            }
        }
        .selectedMenuItem{
            color: var(--font_color_alt);
            font-weight: bold;
            background: var(--primary-color-light_alt);
            border-radius: var(--border_radius);
        }
        .userInfo{
            padding-bottom: var(--padding_default);
            border-bottom: 1px solid var(--border-color);
            margin-bottom: var(--margin_default);
            width: 100%;
            .username{
                font-weight: bold;
                color: var(--font_color);
                padding-bottom: 5px;
            }
            .userrole{
                color: var(--font_color_alt);
            }
        }
        .btnGroup{
            justify-content: space-between;
            align-items: center;
            .logout{
                width: fit-content;
                padding: 15px 20px;
                color: red;
                background: rgb(250, 205, 205);
                border-radius: var(--border_radius);
                cursor: pointer;
            }
        }
        .walletContainer{
            height: fit-content !important;
            margin-bottom: 10px !important;
            .balanceSummary{
                .value{
                    font-size: 14px !important;
                }
                img{
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }
}

.navCancel{
    top: 20px;
    right: 20px;
    z-index: 1;
    img{
        height: 30px;
        width: 30px;
        background: var(--border-color);
        padding: 5px;
        border-radius: var(--border_radius);
    }
    @media screen and (min-width: 768px) {
        display: none;
    }
}
