@import '/src/index.scss';

.treeCardWrap{
    @extend .columnListCard;
}
.varietycard{
    @extend .columnListCard;
    // width: 100%;
    padding: var(--padding_custom);
    background: var(--background-primary);
    .header{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .farmData{
            .meta{
                color: var(--secondary-color);
                padding: 2px 0;
                img {
                    width: 15px;
                    height: 15px;
                    margin-right: 5px;
                }
            }
            .location{
                display: flex;
                align-items: center;
            }
            h6{
                color: var(--secondary-color);
                b{
                    color: var(--font_color);
                }
                @media screen and (max-width: 768px) {
                    font-size: 14px;
                }
            }
        }
    }
    
    // &:hover{
    //     cursor: pointer;
    //     background: var(--background-primary);
    // }
}

.treeCardWrap, .varietycard{
    .treeData{
        display: flex;
        align-items: center;
        background: var(--primary-color-light_alt);
        padding: var(--padding_custom);
        border-radius: var(--border_radius);
        .imgWrap{
            border-radius: 50%;
            padding: 7px;
            margin-right: 10px;
            background: rgba(255, 255, 255, 0.288);
            img{
                width: 40px;
                height: 40px;
            }
        }
        .metaGroup{
            .meta{
                color: var(--secondary-color);
                b{
                    color: var(--font_color_alt);
                }
            }
        }
    }
}