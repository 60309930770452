
.consignmentCard{
    display: flex;
    flex-direction: column;
    // overflow: auto;
    // height: 155px;
    .cardHeader{
        display: flex;
        padding-bottom: var(--padding_default);
        justify-content: space-between;
        flex-direction: row;
        .cardTitle{
            font-size: 14px;
            font-weight: 600;
            color: var(--font_color);
            width: 80%;
        }
        img{
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }
    .cardBody{
        display: flex;
        justify-content: space-between;
    }
    .cardMeta{
        width: 60%;
        .exporter{
            color: var(--secondary-color);
            width: 90%;
        }
        .dateGroup{
            display: flex;
            padding: var(--padding_default) 0;
            justify-content: space-between;
            .dateMeta{
                display: flex;
                flex-direction: column;
                color: var(--secondary-color);
                text-transform: capitalize;
                
                .date{
                    color: var(--font_color_alt);
                    font-weight: 600;
                }
                .stage{
                    color: #324de4;
                }
            }    
        }
    }
    .quantityGroup{
        // display: flex;
        // flex-direction: column;
        height: fit-content;
        border-radius: var(--border_radius);
        padding: var(--padding_custom);
        background: var(--primary-color-light_alt);
        width: 35%;
        text-align: right;
        .meta{
            font-size: 10px;
        }
        .count{
            font-size: 16px;
            font-weight: bold;
            color: var(--font_color_alt);
            span{
                font-size: 10px;
            }
        }
    }
    .cardFooter{
        padding-top: var(--padding_default);
    }
}